import React from 'react';
import css from '@emotion/css';

const Eula = () => {
    return (
        <div
            css={css`
                color: black;
                width: 1000px;
                margin: auto;

                span {
                    display: block;
                }
            `}
        >
            <p>Mapquest End User License Agreement</p>
            <p>
                Your use of this software, including any updates or support you may receive from us (collectively the
                &quot;App&quot;), is governed by this agreement between MapQuest Holdings LLC. (&quot;Mapquest&quot;)
                and you. As used in this agreement, &quot;you&quot; refers to you as an individual and other individuals
                you allow to access or use the App on your computer, and any legal entity you control, work for, or
                represent when you access or use the App. The terms &quot;Mapquest&quot;, &quot;us&quot; and
                &quot;we&quot; refer to MapQuest Holdings LLC., and our affiliated companies and authorized distributors
                and resellers.
            </p>
            <p>
                By installing and using the App, you accept this agreement. If you do not accept this agreement, do not
                install or use the App and you may make no use of the App.
            </p>
            <p>
                1. Licensed Use Rights
                <span>
                    If you comply with this agreement, you may install and use the App for your personal, non-commercial
                    use.
                </span>
            </p>
            <p>
                2. Other License Limitations
                <span>
                    The App is licensed, not sold. The App is protected by copyright and other intellectual property
                    laws and treaties. This agreement only gives you limited rights to use the App. Mapquest reserves
                    all other rights. Unless applicable law gives you more rights despite this limitation, you may use
                    the App only as expressly permitted in this agreement. In doing so, you must comply with technical
                    limitations in the App that only allow you to use it in certain ways. You may not:
                </span>
            </p>
            <p
                css={css`
                    span {
                        display: block;
                    }
                `}
            >
                You may not:
                <span>● work around the technical limitations in the App;</span>
                <span>
                    ● reverse engineer, decompile ordisassemble the App, except and only to the extent that applicable
                    law expressly permits, despite this limitation;
                </span>
                <span>
                    ● install on more computers or make more copies of the App than specified in this agreement or
                    allowed by applicable law, despite this limitation;
                </span>
                <span>● publish the App for others to copy; </span>
                <span>● distribute the App to any third party; </span>
                <span>● rent, lease or lend the App; or </span>
                <span>● transfer the App or this agreement to any third party.</span>
            </p>
            <p>
                3. Privacy
                <span>
                    Information collected while using the App will be handled under Mapquest’s privacy policy. Please
                    refer to Mapquest&apos;s privacy policy, available at (https://hello.mapquest.com/privacy) and
                    Mapquest&apos;s Terms of Use, available at (https://hello.mapquest.com/terms-of-use), prior to
                    agreeing to this agreement for a more detailed explanation of how your information is collected,
                    stored and used by Mapquest and third-party service providers. If the App and related services are
                    involved in a corporate restructuring or a sale, merger or other transfer of assets, we may transfer
                    this agreement and all information provided by or collected from you.
                </span>
            </p>
            <p>
                4. Your Feedback
                <span>
                    If you give feedback about the App to Mapquest, you give to Mapquest, without charge, the right to
                    use that feedback for any purpose. You will not give feedback subject to a license that requires
                    Mapquest to license its software or documentation to third parties because we include your feedback
                    in them. These rights survive this agreement.
                </span>
            </p>
            <p>
                5. U.S. Government Restricted Rights
                <span>
                    The App and its accompanying documentation are deemed commercial computer software as defined in FAR
                    12.212 and subject to restricted rights as defined in FAR Section 52.227-19 &quot;Commercial
                    Computer Software - Restricted Rights&quot; and DFARS 227.7202, &quot;Rights in Commercial Computer
                    Software or Commercial Computer Software Documentation&quot;, and any successor regulations. Any
                    use, modification, reproduction release, performance, display or disclosure of the App by the U.S.
                    Government shall be solely pursuant to this agreement.
                </span>
            </p>
            <p>
                6. Export Restrictions
                <span>
                    The App is subject to U.S. export laws and regulations. You must comply with all domestic and
                    international export laws and regulations that apply to the App. These laws include restrictions on
                    destinations, end users and end use. You agree not to export the App to any prohibited country,
                    entity, or person for which an export license or other governmental approval is required.
                </span>
            </p>
            <p>
                7. Termination
                <span>
                    You may terminate this agreement by uninstalling the App and removing copies from your computer. If
                    you do not comply with this agreement, Mapquest may terminate your right to access and use to the
                    App and this agreement. We may discontinue or alter aspects of the App and services accessible
                    through the App without prior notice to you.
                </span>
            </p>
            <p>
                8. DISCLAIMER OF WARRANTIES
                <span>
                    THE APP IS LICENSED &quot;AS-IS&quot; AND DEFECTS MAY CAUSE IT TO NOT FUNCTION PROPERLY. ALTHOUGH
                    NOT INTENDED, AS WITH ALL COMPUTER SOFTWARE, IT IS POSSIBLE A DEFECT COULD CAUSE YOUR COMPUTER TO
                    FUNCTION IMPROPERLY AND/OR LOSS OF DATA. YOU BEAR THE RISK OF USING THE APP ON YOUR EQUIPMENT.
                    MAPQUEST GIVES NO EXPRESS WARRANTIES, GUARANTEES OR CONDITIONS. YOU MAY HAVE ADDITIONAL CONSUMER
                    RIGHTS UNDER YOUR LOCAL LAWS THAT THIS AGREEMENT CANNOT CHANGE. TO THE FULL EXTENT PERMITTED BY LAW,
                    MAPQUEST EXCLUDES THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                    NON-INFRINGEMENT. MAPQUEST MAKES NO REPRESENTATIONS, WARRANTIES OR COVENANTS REGARDING OR RELATING
                    TO ANY OF THE CONTENT AND SERVICES ACCESSIBLE THROUGH THE APP, INCLUDING THOSE PROVIDED TO MAPQUEST
                    BY THIRD PARTIES AND HEREBY SPECIFICALLY DISCLAIMS ANY CLAIM IN TORT (INCLUDING NEGLIGENCE),
                    CONTRACT OR OTHERWISE ARISING OUT OF OR IN CONNECTION WITH ANY CONTENT OR SERVICES PROVIDED THROUGH
                    THE APP.
                </span>
            </p>
            <p>
                9. LIMITATION ON AND EXCLUSION OF DAMAGES
                <span>
                    MAPQUEST&apos;S LIABILITY UNDER THIS AGREEMENT IS LIMITED. YOU CANNOT RECOVER ANY DAMAGES, INCLUDING
                    DIRECT, CONSEQUENTIAL, LOST PROFITS, SPECIAL, INDIRECT OR INCIDENTAL DAMAGES. THIS LIMITATION
                    APPLIES TO ALL CLAIMS RELATED TO THE APP, INCLUDING WITHOUT LIMITATION CLAIMS FOR BREACH OF
                    CONTRACT, BREACH OF WARRANTY, GUARANTEE OR CONDITION, STRICT LIABILITY, NEGLIGENCE, OR OTHER TORT TO
                    THE EXTENT PERMITTED BY APPLICABLE LAW. IT ALSO APPLIES EVEN IF MAPQUEST KNEW OR SHOULD HAVE KNOWN
                    ABOUT THE POSSIBILITY OF THE DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OR THE LIMITATION OF LIABILITY FOR DAMAGES, IN SUCH STATE OR JURISDICTIONS, MAPQUEST&apos;
                    LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
                </span>
            </p>
            <p>
                10. Applicable Law; Forum
                <span>
                    Washington state law governs interpretation of this agreement and applies to claims for its breach,
                    regardless of conflict of laws principles. You agree that any lawsuit filed to resolve any claim
                    with us arising out of or relating to the App and this agreement will be brought exclusively in a
                    state or federal court in King County, Washington and you agree to submit to the personal
                    jurisdiction of such courts for such claims.
                </span>
            </p>
            <p>
                11. Entire Agreement; Severability
                <span>
                    This Agreement constitutes a complete and exclusive statement of the terms of the agreement between
                    the parties regarding its subject matter. We reserve the right to review and revise this agreement
                    from time to time without prior notice and, by using the App subsequent to any revision of this
                    agreement, you agree to be bound by such changes. If any provision of this agreement is held to be
                    invalid or unenforceable to any extent, then (a) such provision will be interpreted, construed or
                    reformed to the extent reasonably required to render the same valid, enforceable and consistent with
                    the original intent underlying such provision; (b) such provision will remain in effect if it is not
                    invalid or unenforceable; and (c) such invalidity or unenforceability will affect no other provision
                    of this agreement.
                </span>
            </p>
            <p>© 2021 Mapquest Holdings LLC. All Rights Reserved. Last updated April 8, 2021.</p>
        </div>
    );
};

export default Eula;
